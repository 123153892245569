import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Link,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

const services = [
  { name: 'Payment Integration', path: '/services/payment-integration' },
  { name: 'Email Marketing', path: '/services/email-marketing' },
  { name: 'CRM Integration', path: '/services/crm-integration' },
  { name: 'Customer Support', path: '/services/customer-support' },
  { name: 'Chatbot Integration', path: '/services/chatbot-integration' },
  { name: 'Analytics Dashboard', path: '/services/analytics' },
];

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [servicesAnchor, setServicesAnchor] = useState(null);

  const handleServicesClick = (event) => {
    setServicesAnchor(event.currentTarget);
  };

  const handleServicesClose = () => {
    setServicesAnchor(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      <ListItem component={RouterLink} to="/" onClick={handleDrawerToggle}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem>
        <ListItemText 
          primary="Services" 
          onClick={handleServicesClick}
          sx={{ cursor: 'pointer' }}
        />
      </ListItem>
      <ListItem component={RouterLink} to="/about" onClick={handleDrawerToggle}>
        <ListItemText primary="About" />
      </ListItem>
      <ListItem component={RouterLink} to="/contact" onClick={handleDrawerToggle}>
        <ListItemText primary="Contact" />
      </ListItem>
    </List>
  );

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          sx={{
            textDecoration: 'none',
            color: 'primary.main',
            fontWeight: 700,
          }}
        >
          WiseConnect
        </Typography>

        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              underline="none"
            >
              Home
            </Link>
            <Box
              onClick={handleServicesClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'inherit',
              }}
            >
              Services  ▼
            </Box>
            <Link
              component={RouterLink}
              to="/about"
              color="inherit"
              underline="none"
            >
              About
            </Link>
            <Link
              component={RouterLink}
              to="/contact"
              color="inherit"
              underline="none"
            >
              Contact
            </Link>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Box>
        )}

        <Menu
          anchorEl={servicesAnchor}
          open={Boolean(servicesAnchor)}
          onClose={handleServicesClose}
        >
          {services.map((service) => (
            <MenuItem
              key={service.path}
              component={RouterLink}
              to={service.path}
              onClick={handleServicesClose}
            >
              {service.name}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
