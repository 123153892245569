import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import CallToAction from '../../components/home/CallToAction';

const features = [
  {
    title: 'AI-Powered Solutions',
    description: 'Integration with advanced AI chatbot platforms for intelligent conversations.',
  },
  {
    title: 'Multi-Channel Support',
    description: 'Deploy chatbots across website, messaging apps, and social media.',
  },
  {
    title: 'Custom Workflows',
    description: 'Design intuitive conversation paths tailored to your business needs and customer journey',
  },
  {
    title: 'Analytics Dashboard',
    description: 'Track chatbot performance and customer interaction metrics.',
  },
];

const ChatbotIntegration = () => {
  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              Chatbot Integration
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
              Transform your customer service with intelligent AI chatbots
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" gutterBottom>
                Transform Customer Interactions
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                sx={{ mb: 4 }}
              >
              We implement advanced AI chatbots that transform your customer conversations into efficient, personalized interactions.
              </Typography>
              <Typography variant="h4" gutterBottom>
                Why Choose Our Integration?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
              >
              Leveraging cutting-edge AI technology, we deliver customized chatbot solutions that enhance customer experience while reducing operational costs.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} key={feature.title}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: '100%',
                        bgcolor: 'background.light',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {feature.description}
                      </Typography>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <CallToAction />
    </>
  );
};

export default ChatbotIntegration;
