import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import CallToAction from '../../components/home/CallToAction';

const features = [
  {
    title: 'Multi-Platform Support',
    description: 'Integration with leading CRM platforms like Salesforce, HubSpot, and more.',
  },
  {
    title: 'Data Synchronization',
    description: 'Real-time synchronization of customer data across all systems.',
  },
  {
    title: 'Custom Workflows',
    description: 'Create automated workflows tailored to your business processes.',
  },
  {
    title: 'Analytics & Reporting',
    description: 'Comprehensive reporting tools for customer relationship insights.',
  },
];

const CRMIntegration = () => {
  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              CRM Integration
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
              Streamline your customer relationship management with seamless integration
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" gutterBottom>
                Transform Your Customer Relations
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                sx={{ mb: 4 }}
              >
                Our CRM integration service helps businesses connect their customer relationship management systems with existing business tools. We ensure smooth data flow and process automation across all your platforms.
              </Typography>
              <Typography variant="h4" gutterBottom>
                Why Choose Our Integration?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
              >
                With extensive experience in CRM integration, we understand how to optimize customer relationship management processes. Our solution provides seamless data synchronization, automated workflows, and comprehensive analytics to help you make informed decisions.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} key={feature.title}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: '100%',
                        bgcolor: 'background.light',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {feature.description}
                      </Typography>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <CallToAction />
    </>
  );
};

export default CRMIntegration;
