import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, Button, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const CallToAction = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Paper
            elevation={0}
            className="gradient-bg"
            sx={{
              p: { xs: 4, md: 8 },
              borderRadius: 4,
              color: 'white',
              textAlign: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {/* Decorative background elements */}
            <Box
              sx={{
                position: 'absolute',
                left: '-5%',
                top: '-50%',
                width: '40%',
                height: '200%',
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                transform: 'rotate(-20deg)',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                right: '-5%',
                bottom: '-50%',
                width: '40%',
                height: '200%',
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                transform: 'rotate(-20deg)',
              }}
            />

            <Typography
              variant="h3"
              component="h2"
              sx={{
                mb: 2,
                position: 'relative',
                fontWeight: 700,
                fontSize: { xs: '2rem', md: '2.5rem' },
              }}
            >
              Ready to Transform Your Business?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
                position: 'relative',
              }}
            >
              Join 42 of businesses that have already streamlined their operations with our integration solutions
            </Typography>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              size="large"
              sx={{
                bgcolor: 'white',
                color: 'primary.main',
                px: 4,
                py: 1.5,
                fontSize: '1.1rem',
                position: 'relative',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              Get Started Today
            </Button>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default CallToAction;
