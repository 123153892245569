import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import CallToAction from '../../components/home/CallToAction';

const features = [
  {
    title: 'Multiple Gateway Support',
    description: 'Integration with popular payment gateways including IcePay, PayPal and BTCpayserver.',
  },
  {
    title: 'Secure Customer Data Storage',
    description: 'Compliant integration ensuring the secure storage and handling of customer data.',
  },
  {
    title: 'Tailored Implementation',
    description: 'We deliver customized integration strategies tailored to your unique business needs.',
  },
  {
    title: 'Real-time Processing',
    description: (
      <>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Instant payment updates</li>
          <li>Transaction status</li>
          <li>Predictive forecasting</li>
        </ul>
        <Box sx={{ mb: 1 }} />
        <Typography variant="body1">
          Based on historical sales performance.
        </Typography>
      </>
    ),
  },
];

const PaymentIntegration = () => {
  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              Payment Gateway Integration
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
              Seamlessly integrate payment processing into your business systems
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" gutterBottom>
                Streamline Your Payments
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                sx={{ mb: 4 }}
              >
                We help businesses implement secure and efficient payment processing solutions by creating custom webpages that guide customers through each payment step while securely fetching and managing their data.
              </Typography>
              <Typography variant="h4" gutterBottom>
                Why Choose Our Integration?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
              >
                With years of experience in payment integration, we understand the complexities involved in connecting payment systems. Our solution provides robust security, real-time processing, and comprehensive reporting capabilities.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} key={feature.title}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: '100%',
                        bgcolor: 'background.light',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {feature.description}
                      </Typography>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <CallToAction />
    </>
  );
};

export default PaymentIntegration;
