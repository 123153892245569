import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './styles/theme';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import About from './pages/About';
import PaymentIntegration from './pages/services/PaymentIntegration';
import EmailMarketing from './pages/services/EmailMarketing';
import CRMIntegration from './pages/services/CRMIntegration';
import CustomerSupport from './pages/services/CustomerSupport';
import ChatbotIntegration from './pages/services/ChatbotIntegration';
import Analytics from './pages/services/Analytics';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/payment-integration" element={<PaymentIntegration />} />
            <Route path="/services/email-marketing" element={<EmailMarketing />} />
            <Route path="/services/crm-integration" element={<CRMIntegration />} />
            <Route path="/services/customer-support" element={<CustomerSupport />} />
            <Route path="/services/chatbot-integration" element={<ChatbotIntegration />} />
            <Route path="/services/analytics" element={<Analytics />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
