import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { motion } from 'framer-motion';

const contactInfo = [
  {
    icon: EmailIcon,
    title: 'Email',
    content: 'contact@WiseConnect.net',
  },
  {
    icon: PhoneIcon,
    title: 'Phone',
    content: '+31 6 421 99 222',
  },
  {
    icon: LocationOnIcon,
    title: 'Location',
    content: 'Veldhoven, Netherlands',
  },
];

const Contact = () => {
  useEffect(() => {
    // Dynamically load GetResponse script
    const script = document.createElement('script');
    script.src = 'https://www.getresponse.com/js/webforms/gr-form-embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              Get in Touch
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
              Have questions about our integration solutions? We're here to help!
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'sticky', top: 100 }}>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography variant="h4" gutterBottom sx={{ mt: 2, color: 'black' }}>
                  Contact Information
                </Typography>
                <br />
                <Typography
                  variant="body1"
                  color="black"
                  paragraph
                  sx={{ mb: 4 }}
                >
                  Reach out to us for any questions about our services or to discuss your integration needs.
                </Typography>

                {contactInfo.map((info, index) => (
                  <motion.div
                    key={info.title}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        mb: 2,
                        bgcolor: 'background.light',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        sx={{
                          mr: 2,
                          bgcolor: 'primary.main',
                          borderRadius: 1,
                          p: 1,
                          color: 'white',
                        }}
                      >
                        <info.icon />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                          {info.title}
                        </Typography>
                        <Typography variant="body1">{info.content}</Typography>
                      </Box>
                    </Box>
                  </motion.div>
                ))}
              </motion.div>
            </Box>
          </Grid>

          <Grid 
            item 
            xs={12} 
            md={8} 
            sx={{
              mt: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box 
              sx={{
                width: '100%',
                mt: 0,
                position: 'relative',
                top: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <getresponse-form 
                  form-id="9f6cec92-0808-4296-a02b-e85766c0ccd1" 
                  e="0"
                  style={{
                    marginTop: 0,
                    position: 'relative',
                    top: 0,
                  }}
                ></getresponse-form>
              </motion.div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;