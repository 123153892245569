import React from 'react';
import Hero from '../components/home/Hero';
import ServiceOverview from '../components/home/ServiceOverview';
import CallToAction from '../components/home/CallToAction';
import Diagram from '../components/home/Diagram';
import './styles.css';

const Home = () => {
  return (
    <>
      <Hero />
      <ServiceOverview />
      <Diagram />
      <CallToAction />
    </>
  );
};

export default Home;
