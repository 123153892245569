import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.light',
        py: 6,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: 'none',
                color: 'primary.main',
                fontWeight: 700,
                mb: 2,
                display: 'block',
              }}
            >
              WiseConnect
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Seamless Integration Solutions for Modern Businesses
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton
                component="a"
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                component="a"
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                component="a"
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <FacebookIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Services
            </Typography>
            <Link
              component={RouterLink}
              to="/services/payment-integration"
              color="text.secondary"
              display="block"
              sx={{ mb: 1 }}
            >
              Payment Integration
            </Link>
            <Link
              component={RouterLink}
              to="/services/email-marketing"
              color="text.secondary"
              display="block"
              sx={{ mb: 1 }}
            >
              Email Marketing
            </Link>
            <Link
              component={RouterLink}
              to="/services/crm-integration"
              color="text.secondary"
              display="block"
              sx={{ mb: 1 }}
            >
              CRM Integration
            </Link>
            <Link
              component={RouterLink}
              to="/services/customer-support"
              color="text.secondary"
              display="block"
              sx={{ mb: 1 }}
            >
              Customer Support
            </Link>
            <Link
              component={RouterLink}
              to="/services/chatbot-integration"
              color="text.secondary"
              display="block"
            >
              Chatbot Integration
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Quick Links
            </Typography>
            <Link
              component={RouterLink}
              to="/about"
              color="text.secondary"
              display="block"
              sx={{ mb: 1 }}
            >
              About Us
            </Link>
            <Link
              component={RouterLink}
              to="/contact"
              color="text.secondary"
              display="block"
              sx={{ mb: 1 }}
            >
              Contact
            </Link>
            <Link
              component={RouterLink}
              to="/services"
              color="text.secondary"
              display="block"
            >
              All Services
            </Link>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 4 }}
        >
          © {new Date().getFullYear()} WiseConnect. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
