import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { motion } from 'framer-motion';

const ServiceCard = ({ icon: Icon, title, description, link }) => {
  return (
    <motion.div
      whileHover={{ y: -10 }}
      transition={{ duration: 0.3 }}
    >
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          '&:hover': {
            boxShadow: 6,
          },
        }}
      >
        <CardContent sx={{ flexGrow: 1, p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'primary.main',
              width: 60,
              height: 60,
              borderRadius: 2,
              mb: 2,
            }}
          >
            <Icon sx={{ color: 'white', fontSize: 30 }} />
          </Box>
          <Typography variant="h5" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: 3 }}
          >
            {description}
          </Typography>
          <Button
            component={RouterLink}
            to={link}
            color="primary"
            sx={{
              textTransform: 'none',
              '&:hover': {
                bgcolor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Learn More →
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default ServiceCard;
