import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChatIcon from '@mui/icons-material/Chat';
import ServiceCard from '../common/ServiceCard';
import { motion } from 'framer-motion';

const services = [
  {
    icon: PaymentIcon,
    title: 'Payment Gateway Integration',
    description: 'Seamlessly integrate popular payment gateways like Icepay, Stripe, and PayPal into your business systems.',
    link: '/services/payment-integration'
  },
  {
    icon: EmailIcon,
    title: 'Email Marketing Integration',
    description: 'Connect your email marketing platforms with your existing systems for automated and effective campaigns.',
    link: '/services/email-marketing'
  },
  {
    icon: SupportAgentIcon,
    title: 'Customer Support Integration',
    description: 'Enhance your customer service with integrated support systems and ticketing solutions.',
    link: '/services/customer-support'
  },
  {
    icon: BusinessIcon,
    title: 'CRM Integration',
    description: 'Integrate your CRM systems to streamline customer data management and improve business relationships.',
    link: '/services/crm-integration'
  },
  {
    icon: ChatIcon,
    title: 'Chatbot Integration',
    description: 'Implement AI-powered chatbots to provide 24/7 customer support and improve user engagement.',
    link: '/services/chatbot-integration'
  },
  {
    icon: BusinessIcon,
    title: 'Analytics',
    description: 'Gain insights at every step of the customer journey with your comprehensive Analytics Dashboard.',
    link: '/services/analytics'
  }
];

const ServiceOverview = () => {
  return (
    <Box sx={{ py: 8, bgcolor: 'background.light' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{ mb: 1 }}
          >
            Our Services
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            sx={{ mb: 6, maxWidth: 800, mx: 'auto' }}
          >
            Comprehensive integration solutions tailored to your business needs
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={service.title}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
              >
                <ServiceCard {...service} />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ServiceOverview;
