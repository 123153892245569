import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import CallToAction from '../../components/home/CallToAction';

const features = [
  {
    title: 'Ticketing Integration',
    description: 'Seamless integration with popular helpdesk and ticketing systems.',
  },
  {
    title: 'Unified Communication',
    description: 'Centralize customer communications across all channels.',
  },
  {
    title: 'Automated Responses',
    description: ' AI-powered responses for frequently asked questions and common support issues.',
  },
  {
    title: 'Performance Analytics',
    description: 'Comprehensive reporting on support team performance and metrics.',
  },
];

const CustomerSupport = () => {
  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              Customer Support Integration
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
              Enhance your customer support with integrated helpdesk solutions
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" gutterBottom>
                Elevate Your Customer Support
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                sx={{ mb: 4 }}
              >
Our integration service connects your helpdesk systems with your existing business tools to streamline support operations and boost efficiency.
              </Typography>
              <Typography variant="h4" gutterBottom>
                Why Choose Our Integration?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
              >
                With our expertise in customer support systems, we help businesses create a unified support experience. Our solution provides automated workflows, centralized communication channels, and comprehensive analytics to enhance customer satisfaction.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} key={feature.title}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: '100%',
                        bgcolor: 'background.light',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {feature.description}
                      </Typography>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <CallToAction />
    </>
  );
};

export default CustomerSupport;
