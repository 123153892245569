import React from 'react';

const Diagram = () => {
  return (
    <div className="container">
      <br />
      <div className="text-content">
        <h1>Decoupled Marketing System Architecture</h1>
      </div>
      <div className="text-content">
        <p>
          The decoupled architecture patterns we use in our marketing systems help streamline communication, customer support, and data handling. By centralizing your website as the core platform while integrating external services for payments, support, and analytics, we reduce complexity and lower ongoing maintenance costs. This flexible, modular approach ensures scalability, adaptability, and resilience, all while keeping your marketing and sales operations streamlined and future-proofed.
        </p>
      </div>
      <br />

      <div className="diagram" aria-label="Architecture Diagram">
        <div className="raw-data-client">
          <h4>Email Marketing</h4>
          <p>Platforms: GetResponse, Mailchimp, HubSpot, Mautic. Automates customer outreach and manages campaigns.</p>
        </div>
        <div className="raw-data-server">
          <h4>ChatBot</h4>
          <p>A data-integrated chatbot that allows customers to ask real-time questions, boosting engagement.</p>
        </div>
        <div className="pivot-engine component">
          <h4>Website</h4>
          <p>The central hub for showcasing product and service offerings, collecting leads, and facilitating conversions.</p>
        </div>
        <div className="pivot-panel component">
          <h4>Payments</h4>
          <p>Securely handles transactions, feeding payment data into fulfillment systems for seamless order processing.</p>
        </div>
        <div className="pivot-grid component">
          <h4>Customer Support</h4>
          <p>Manages pre- and post-sale inquiries, providing seamless assistance and enhancing customer satisfaction.</p>
        </div>
        <div className="pivot-chart component">
          <h4>Analytics Dashboard</h4>
          <p>Displays key performance metrics, campaign results, and customer insights through an intuitive dashboard.</p>
        </div>

        {/* connectors */}
        <div className="conn row-2 col-4"> {/* client data => engine */}
          <svg viewBox="0 0 10 10">
            <path d="M5 10 L1 5 L4 5 L4 -9 L6 -9 L6 5 L9 5z" />
          </svg>
        </div>
        <div className="conn row-2 col-8"> {/* server data => engine */}
          <svg viewBox="0 0 10 10">
            <path d="M5 10 L1 5 L4 5 L4 -9 L6 -9 L6 5 L9 5z" />
          </svg>
        </div>
        <div className="conn row-3 col-9"> {/* engine <=> panel */}
          <svg viewBox="0 -5 10 10">
            <path d="M0 5 L4 2 L4 4 L6 4 L6 6 L4 6 L4 8z" />
            <path d="M10 5 L6 2 L6 4 L4 4 L4 6 L6 6 L6 8z" />
          </svg>
        </div>
        <div className="conn row-4 col-4"> {/* engine => grid */}
          <svg viewBox="0 0 10 10">
            <path d="M5 10 L1 5 L4 5 L4 -9 L6 -9 L6 5 L9 5z" />
          </svg>
        </div>
        <div className="conn row-4 col-8"> {/* engine => chart */}
          <svg viewBox="0 0 10 10">
            <path d="M5 10 L1 5 L4 5 L4 -9 L6 -9 L6 5 L9 5z" />
          </svg>
        </div>
      </div>

      <div className="text-content">
      <br></br>
      <br></br>
        <h2>The website is the core </h2>
        <p>
          The Website serves as the core platform for customer interaction, integrating seamlessly with email and Google AdWords campaigns, chat support, payment processing, and analytics. This design offers a streamlined approach to managing marketing and customer support efforts, providing both flexibility and ease of use.
        </p>
        <br></br>
        <p>
          By adopting a <strong>simple decoupled website architecture</strong>, businesses can achieve greater agility and scalability. A decoupled structure means that your website can act as the central sales system, with different components like payment gateways, customer support tools, and analytics services sourced externally. This significantly reduces complexity and lowers ongoing maintenance costs, as it eliminates the need for constant in-house development and system updates.
        </p>
        <br></br>
        <p>
          While most functionalities can be integrated through external services, there are still scenarios where <strong>self-hosted or custom-coded solutions</strong> are necessary—especially when unique business needs or proprietary systems come into play. This is where we come in. We specialize in connecting all the pieces, adding <strong>modular customizations</strong> to suit your exact requirements.
        </p>
        <br></br>
        <p>
          We also design our integrations with <strong>resilience</strong> in mind, considering that <strong>APIs can go down</strong> and services may need to be replaced as your business evolves. By ensuring your systems are adaptable, we future-proof your platform, allowing it to seamlessly evolve as your business and its needs change.
        </p>
      </div>
    </div>
  );
};

export default Diagram;
