import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SecurityIcon from '@mui/icons-material/Security';
import SupportIcon from '@mui/icons-material/Support';
import PhoneIcon from '@mui/icons-material/Phone';
import { motion } from 'framer-motion';

const About = () => {
  const features = [
    {
      icon: IntegrationInstructionsIcon,
      title: 'Expert Integration',
      description: 'Years of experience in delivering seamless integration solutions for businesses of all sizes.',
    },
    {
      icon: SecurityIcon,
      title: 'Secure & Reliable',
      description: 'Every integration we design includes top-tier security measures, ensuring reliability and peace of mind.',
    },
    {
      icon: SupportIcon,
      title: 'Flexible, On-Demand Support',
      description: 'Get specialized expertise on-demand with continuous technical support and maintenance',
    },
    {
      icon: PhoneIcon,
      title: 'SLA-Backed Commitment',
      description: 'Our SLAs guarantee priority response times and proactive monitoring to keep your business running smoothly',
    }
  ];

  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              About WiseConnect
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
            Streamline your business operations through powerful, secure integrations
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        {/* Full-width Meet WiseConnect block */}
        <Box sx={{ width: '100%', mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Meet WiseConnect
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph sx={{ mb: 4 }}>
            WiseConnect was born from our founder’s journey through countless frustrating integration issues — 
            dealing with unreliable WooCommerce plugins that requiring customers to complete unnecessary fields, 
            experiencing updates that reversed critical settings and interrupted sales, and transactional emails that were 
            nearly impossible to customize (if they even arrived). These ongoing challenges made it clear: 
            businesses need a simpler, more reliable way to connect their systems without the constant headaches.
            <br /><br />
            At WiseConnect, Our mission is to help businesses operate more efficiently by eliminating technical 
            barriers through seamless, secure, and reliable integrations. We know that when workflows are streamlined 
            and resilient, companies can focus on their core goals: growth, innovation, and delivering outstanding 
            experiences to their customers.
            <br /><br />
            Through a deep network of experts and years of hands-on experience, WiseConnect builds powerful, flexible 
            integrations that solve real problems. With every integration, whether it’s payment gateways or email 
            marketing tools like GetResponse, Our integrations go beyond basic functionality to deliver optimal 
            performance, making even complex processes smooth and intuitive.
          </Typography>
        </Box>

        {/* Full-width Founder block with gray background */}
        <Box sx={{ backgroundColor: 'background.light', p: 3, borderRadius: 2, width: '100%', mb: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%', marginTop: 6 }}>
                <img src="https://www.wiseconnect.net/leonweb.jpg" alt="Leon Berkers" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography variant="h5" gutterBottom>
                  Founder, Leon Berkers, MSc
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph sx={{ mb: 4 }}>
                  Leon Berkers, an IT professional with over 20 years of experience, leads WiseConnect with a 
                  deep expertise in IT infrastructure, cloud solutions, and DevOps practices. Based in Veldhoven, 
                  Leon’s career includes work with major companies like Enexis and ASML, where he specialized 
                  in cloud infrastructure, automation, and secure network management. His technical proficiencies 
                  encompass AWS, Docker, CI/CD, and advanced network security, ensuring seamless integration 
                  solutions for complex business needs.
                  <br /><br />
                  Beyond large enterprises, Leon also has extensive experience as an internet marketer, giving 
                  him firsthand insights into the marketing and sales challenges of small and medium-sized 
                  businesses (SMEs). He understands the real-world issues SMEs face, from digital marketing 
                  obstacles to optimizing sales processes, and brings this knowledge to his work at WiseConnect. 
                  His consulting background and technical leadership equip him to deliver solutions that reduce 
                  manual work, improve sales functionality, and enhance customer engagement.
                  <br /><br />
                  With a Master of Science in Business Administration from Radboud University and numerous cloud 
                  and security certifications, Leon ensures that WiseConnect empowers businesses through tailored 
                  integration solutions designed for both efficiency and growth. His unique blend of IT and marketing 
                  experience makes him a valuable ally to businesses striving for operational excellence.
                </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </Box>

        {/* Duplicated Founder block */}
        <Box sx={{ backgroundColor: 'background.light', p: 3, borderRadius: 2, width: '100%', mb: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography variant="h5" gutterBottom>
                  External advisor, Tom Lassing
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph sx={{ mb: 4 }}>
                  Tom Lassing, an accomplished entrepreneur with over 30 years of experience, serves as an 
                  external advisor specializing in feasibility assessments and project viability evaluations.
                  Based in Best, Tom has founded and managed multiple successful ventures, including Pro Gain BV and BeursAccent BV, 
                  focusing on affiliate marketing and investment services. His expertise encompasses online entrepreneurship and 
                  strategic planning, ensuring effective solutions for diverse business challenges.
                  <br /><br />
                  Beyond his entrepreneurial pursuits, Tom is dedicated to volunteer work, having committed significant 
                  time to community initiatives since 1987. His roles include youth athletics coach and club chairman, 
                  where he fosters youth engagement and community spirit. Tom’s extensive involvement in local organizations 
                  provides valuable insights into the operational challenges faced by small and medium-sized enterprises (SMEs).
                  <br /><br />
                  With a strong foundation in business management and a passion for service, Tom helps organizations navigate 
                  complex project assessments. His combination of analytical rigor and entrepreneurial intuition delivers 
                  actionable insights that enhance decision-making and drive growth.
                  <br /><br />
                  Recognized for his community contributions with a royal distinction in April 2023, Tom empowers businesses 
                  through tailored advisory services designed for sustainability and success. His unique blend of expertise and 
                  community involvement makes him a trusted partner for organizations striving for operational excellence.  
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%', marginTop: 6 }}>
                <img src="https://www.wiseconnect.net/tomweb.jpg" alt="Tom Lassing" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* "Our Mission" section without gray background */}
        <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 4 }}>
          Our Mission
        </Typography>
        {/* Additional content for the mission can go here */}
        
        {/* Grid for features */}
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} key={feature.title}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    bgcolor: 'background.light',
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      sx={{
                        mr: 2,
                        bgcolor: 'primary.main',
                        borderRadius: 1,
                        p: 1,
                        color: 'white',
                      }}
                    >
                      <feature.icon />
                    </Box>
                    <Typography variant="h6">{feature.title}</Typography>
                  </Box>
                  <Typography color="text.secondary">
                    {feature.description}
                  </Typography>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default About;
