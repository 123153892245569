import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ServiceOverview from '../components/home/ServiceOverview';

const Services = () => {
  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            component="h1"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            Our Integration Services
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 4,
              maxWidth: '800px',
              mx: 'auto',
              opacity: 0.9,
            }}
          >
            Discover our comprehensive range of integration solutions designed to streamline your business operations at WiseConnect
          </Typography>
        </Container>
      </Box>
      <ServiceOverview />
    </>
  );
};

export default Services;
